import React, { lazy, Suspense, useState } from "react";
import "./campaigns.css";
import Table from "../../common/components/Table";
import { useGetCampaignsQuery } from "../../app/apiSlice";
import Loader from "../../common/components/Loader";

import AdvancedFilter from "./AdvancedFilter";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import ErrorHandler from "../../common/components/ErrorHandler";
import PermissionsCheck from "../auth/Permissions/PermissionsCheck";
import { SCOPES } from "../auth/Permissions/permissionsMap";
import ProtectedRoute from "../../common/components/ProtectedRoute";
import useHasPermission from "../../common/hooks/useHasPermission";
import { sortByString } from "../../common/utils/utils";

const CampaignEdit = lazy(() => import("./campaignEdit/CampaignEdit"));
const NewCampaign = lazy(() => import("./campaignCreation/CreateCampaign"));

const Campaigns = ({ user }) => {
  const canEditCampaigns = useHasPermission([SCOPES.canEditCampaigns]);

  return (
    <Routes>
      <Route path="/" element={<CampaignsLayout />} />
      <Route
        path=":campaignId/*"
        element={
          <Suspense fallback={<Loader message="Loading Campaign..." />}>
            <CampaignEdit />
          </Suspense>
        }
      />
      <Route
        element={<ProtectedRoute isAllowed={!!user && canEditCampaigns} />}
      >
        <Route
          path="/new_campaign"
          element={
            <Suspense fallback={<Loader message="Loading Campaign..." />}>
              <NewCampaign />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

const CampaignsLayout = () => {
  const navigate = useNavigate();
  const [activeToggle, setActiveToggle] = useState(true);
  // Campaign fetch.
  const {
    data: records, // Campaigns
    error, // Errors.
    isLoading, // Loading boolean.
  } = useGetCampaignsQuery(); // Query hook call with options.

  // Loading Render
  if (isLoading) {
    return <Loader message="Loading Campaigns..." />; // Loading component.
  }

  // catch error in call.
  if (error !== undefined) {
    return (
      <ErrorHandler
        status={error?.data?.code}
        error={error?.data?.errors[0]?.detail}
        message={error?.data?.errors[0]?.message}
      />
    ); // Error handling component goes here
  }

  // Prepare header for Table component.
  const headers = [
    { text: "Name", id: "name", type: "string" },
    { text: "UUID Copy" }, // Blank header for uuid copy icon.
    { text: "Short Code", id: "shortCode", type: "string" },
    { text: "Rate", id: "rate", type: "number" },
  ];

  // Prapare campaigns for Table component.
  return (
    <>
      <div>
        <div className="grid-container campaigns-toolbar">
          <AdvancedFilter data={records} />
          <PermissionsCheck scopes={[SCOPES.canEditCampaigns]}>
            <Link to="new_campaign" className="btn btn-secondary btn-rounded">
              Create Campaign
            </Link>
          </PermissionsCheck>
        </div>

        <Table
          title="Campaigns"
          headers={headers}
          rows={sortByString(records, "name")}
          bordered={true}
          customButton={
            <div
              style={{
                marginLeft: "4px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => setActiveToggle(!activeToggle)}
                className={
                  activeToggle
                    ? "btn-secondary btn-rounded"
                    : "btn-primary btn-rounded"
                }
              >
                {activeToggle ? "Active" : "Inactive"}
              </button>
            </div>
          }
          customButtonToggle={activeToggle}
          filterFields={["name", "UUID", "shortCode"]}
          sortOptions={{ field: "", type: "active", order: true }}
          formatFunction={({ UUID: id, name, isActive, shortCode, rate }) => {
            return {
              id,
              isActive,
              name: {
                text: name,
                subtext: id,
                onClick: () => navigate(`/campaigns/${id}/details`),
              },
              clip: {
                icon: "link",
                onClick: () => navigator.clipboard.writeText(id),
                tooltip: "Copied UUID to clipboard!",
              },
              shortCode: shortCode,

              rate: `$${rate}`,
            };
          }}
          // tableActions={true}
          // onLink={(id) => `${id}/details`}
        />
      </div>
    </>
  );
};

export default Campaigns;
