import { createSelector } from "@reduxjs/toolkit";

const selectUser = (state) => state.auth.user;

export const roleSelector = createSelector([selectUser], (user) => {
  if (!user) {
    return [];
  }
  const memberOf = user.profile.memberOf.filter((m) => m.includes("ADQMGR"));
  return memberOf.map((m) => m.split(" ")[1].split(",")[0]);
});
