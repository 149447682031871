import React from "react";
import { useDispatch } from "react-redux";

import "./login.css";
import { login } from "./authActions";

const Login = () => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(login());
  };
  return (
    <div className="grid-container-full login-container">
      <div className="login">
        <img alt="" src={"/AdQuire-Manager-logo-white.png"} />
        <button className="btn btn-primary" onClick={handleLogin}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
