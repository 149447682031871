// File for the Header component.

import React from "react";
import HeaderItem from "./HeaderItem"; // Import the HeaderItem component.
import { useDispatch } from "react-redux";
import { logout } from "../auth/authActions";
import "./header.css";
import { SCOPES } from "../auth/Permissions/permissionsMap";
import PermissionsCheck from "../auth/Permissions/PermissionsCheck";
import { sortByString } from "../../common/utils/utils";

const Header = () => {
  const dispatch = useDispatch();

  const elementManagementPaths = [
    { text: "campaigns", permission: [SCOPES.canViewCampaigns] },
    { text: "placements", permission: [SCOPES.canViewPlacements] },
    // "accounts",
    // "categories",
  ];

  const reportingPaths = [
    { text: "ADQ-Performance" },
    { text: "Validation" },
    { text: "Show Offers" },
    { text: "Brokered Lead Viewer" },
    { text: "AMPE Failed Leads" },
    { text: "Pub Prevalidations" },
    { text: "Tru Intent" },
    { text: "Session Viewer" },
    { text: "Activity Log" },
    { text: "Campaign Quick Status" },
    { text: "Internal Budget" },
    { text: "Buy Sheet", permission: [SCOPES.canEditReporting] },
    { text: "Deliverable Viewer" },
    { text: "Quarantine" },
  ];

  const opsPaths = [
    {
      text: "Tools",
      permission: [SCOPES.canViewEmailSuppression, SCOPES.canViewZipSearchTool],
    },
    { text: "Bad Words", permission: [SCOPES.canViewBadWords] },
    { text: "Blocked Domains", permission: [SCOPES.canViewBlockedDomains] },
    { text: "Blocked Emails", permission: [SCOPES.canViewBlockedEmails] },
    {
      text: "Blocked Area Codes",
      permission: [SCOPES.canViewBlockedAreaCodes],
    },
    {
      text: "Validation Management",
      permission: [SCOPES.canViewValidationManagement],
    },
    { text: "Contact Management", permission: [SCOPES.canViewTagManagement] },
    { text: "Darwin Groups", permission: [SCOPES.canViewDarwinGroups] },
  ];

  return (
    <aside className="adquire-header">
      <ul className="header-list" style={{ height: "100%", margin: "0px" }}>
        {/* List of Header Items */}
        <HeaderItem itemType="logo" src={"/AdQuire-Manager-logo-white.png"} />
        <HeaderItem
          text="Elements"
          itemType="nav"
          icon="assessment"
          paths={elementManagementPaths}
        />
        <PermissionsCheck scopes={[SCOPES.canViewOps]}>
          <HeaderItem
            text="Ops/QA"
            paths={opsPaths}
            itemType="nav"
            icon="handyman"
            prefix="ops/"
          />
        </PermissionsCheck>
        <PermissionsCheck scopes={[SCOPES.canViewReporting]}>
          <HeaderItem
            text="Reporting"
            itemType="nav"
            icon="trending_up"
            paths={sortByString(reportingPaths, "text")}
            prefix="reports/"
          />
        </PermissionsCheck>
        <PermissionsCheck scopes={[SCOPES.canEditCampaigns]}>
          <HeaderItem
            text="Create"
            paths={[
              { text: "New Campaign", permission: [SCOPES.canEditCampaigns] },
              { text: "New Placement", permission: [SCOPES.canEditPlacements] },
            ]}
            itemType="nav"
            icon="add_box"
            pathSpecificPrefixes={["campaigns/", "placements/"]}
          />
        </PermissionsCheck>
        <HeaderItem
          text="Logout"
          icon="logout"
          onClick={() => dispatch(logout())}
        />
      </ul>
    </aside>
  );
};

export default Header;
