import React from "react";

const ErrorHandler = ({ status, message, error, style }) => {
  return (
    <div className="error-container" style={{ ...style }}>
      <h1 style={{ color: "rgba(var(--secondary-red),1)" }}>{status}</h1>
      <p style={{ margin: "0px" }}>{message}</p>
      {error}
    </div>
  );
};

export default ErrorHandler;
